<template>
  <div>
    <el-row>
      <el-col>
        <head-layout
          :head-title="$t(`cip.plat.app.clock.title.indexHeadTitle`)"
          @head-remove="headRemove"
          @head-add-tabs="headAddTabs"
        ></head-layout>
      </el-col>
    </el-row>

    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>

    <grid-layout
      ref="gridLayOut"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @page-refresh-change="onLoad"
    >
    </grid-layout>
  </div>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {getClockPage,remove} from "@/api/system/attendance";

export default {
name: "clockingRecord",
  components: {
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      selectionList: [],
      tableLoading: true,
      tableData: [],
      dataTotal: null,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
    };
  },
  computed: {
    searchColumns() {
      return [
        {
          prop: "address",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t("cip.plat.app.clock.field.address"),
        },
      ]
    },
    tableOptions() {
      return {
        menu:false,
        height: 0,
        customAddBtn: true,
        column: [
          {
            label: this.$t('cip.plat.app.clock.field.username'),
            prop: 'userName',
            width: 150,
            align: "center",
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.app.clock.field.address'),
            prop: 'address',
            align: "left",
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.app.clock.field.clockTime'),
            prop: 'clockTime',
            width: 200,
            align: "center",
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.app.clock.field.clockOutTime'),
            prop: 'ClockOutTime',
            width: 200,
            align: "center",
            overHidden: true,

          },

        ],
      }
    },
    headBtnOptions() {
      return [
        {
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: 'add',
        },
        {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: 'remove',
        }
      ]
    },
  },
  methods: {
    headRemove(){
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          let ids=this.selectionList.map(item=>{return item.id}).join(',')
          return remove(ids);
        })
        .then(() => {
          // 刷新表格数据并重载
          this.data = [];
          this.parentId = 0;
          this.$refs.gridLayOut.selectionClear();
          // 表格数据重载
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    headAddTabs() {
      this.$router.push({
        path: `/attendance/add`,
        query: {
          type: 'add',
          pageType: 'add',
        }
      })
    },
    gridHeadSearch(query) {
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.onLoad(this.page, query)
    },
    rowDetail(row) {
      this.$router.push({
        path: `/attendance/add`,
        query: {
          id: row.id,
          type: 'view',
          pageType: 'detail',
        }
      })
    },

    rowRemove(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
        });
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      getClockPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm)
      ).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    },
    rowEdit(row) {
      this.$router.push({
        path: `/attendance/add`,
        query: {
          id: row.id,
          type: 'edit',
          pageType: 'edit',
        }
      })
    },
  },
  mounted() {
    this.onLoad(this.page)
  }
}
</script>

<style scoped>

</style>
